<template>
  <core-view fill-height>
    <template>
      <div v-if="!loading" class="center">
        <form>
          <div class="linha">
            <btn-auto-complete
              label="Atividade"
              :dados="autocomplete.atividade"
              icon="mdi-chevron-down"
              v-model="lancamento.atividade"
              itemLabel="atadescricao"
              @change="$_toStorage('atividade', 'atadescricao')"
            >
            </btn-auto-complete>
          </div>
          <div class="linha">
            <btn-auto-complete
              label="Operador"
              :redirect="rotaFiltros[0]"
              :dados="autocomplete.operador"
              v-model="lancamento.operador"
              itemLabel="pesnome"
              @change="$_toStorage('operador','pesnome')"
            >
            </btn-auto-complete>
          </div>
          <div class="linha">
            <btn-auto-complete
              label="Propriedade"
              icon="mdi-chevron-down"
              :dados="autocomplete.propriedade"
              v-model="lancamento.propriedade"
              itemLabel="pronome"
              @change="$_toStorage('propriedade','pronome')"
            >
            </btn-auto-complete>
          </div>

          <div class="divinferior">
            <v-btn
              height="45"
              class="green botaoinferior"
              @click="$_salvaDados"
            >
              Salvar
            </v-btn>
          </div>
        </form>
      </div>
    </template>
  </core-view>
</template>

<script>
import CoreView from "@/components/core/view/Index.vue";
import BtnAutoComplete from "@/components/core/btn-auto-complete/Index.vue";
import { ROUTE_APONTAMENTO, ROUTE_OPERADOR } from "@/constants/ROUTES";
import errorHandler from "@/utils/error-handler";
import db from "@/plugins/dexie"
import moment from "moment-timezone"
import { LANCAMENTO_STORAGE } from '@/constants/STORAGE';
import { SetupWebSocket, WsEnviaDados } from '@/utils/ws/websocket';

//
function getSessionStorageParse(key, defaultValue) {
  const retorno = sessionStorage.getItem(key) ?? defaultValue;
  try {
    return JSON.parse(retorno);
  } catch {
    return retorno;
  }
}
//
export default {
  name: "APCadastro",
  components: {
    CoreView,
    BtnAutoComplete,
  },
  data() {
    return {
      rotaFiltros: [ROUTE_OPERADOR],
      loading: true,
      autocomplete: {
        atividade: null,
        operador: null,
        propriedade: null,
      },
      lancamento: {
        atividade: '',
        operador: '',
        propriedade: '',
        data: moment().format('YYYY-MM-DD'),
      }
    };
  },
  methods: {
    async $_salvaDados() {
      //Pega o idnuvem do usuario logado no momento
      const idnuvem = getSessionStorageParse('user:useidnuvem', null)
      
      /* let now = moment();
      now = now.subtract(3, 'hours') */

      const novoApontamento = {
        lanidatividade: (getSessionStorageParse(LANCAMENTO_STORAGE.atividade,'')).ataidatividadeabastecimento,
        lanatividade: (getSessionStorageParse(LANCAMENTO_STORAGE.atividade,'')).atadescricao,
        lanidoperador: (getSessionStorageParse(LANCAMENTO_STORAGE.operador,'')).pesidpessoa,
        lanoperador: (getSessionStorageParse(LANCAMENTO_STORAGE.operador,'')).pesnome,
        lanpropriedade: (getSessionStorageParse(LANCAMENTO_STORAGE.propriedade,'')).pronome,
        lanidpropriedade: (getSessionStorageParse(LANCAMENTO_STORAGE.propriedade,'')).proidpropriedade,
        landatacriacao: new Date()
      };
      
      const novoApontamentoNuvem = {
        laaidatividade: this.lancamento.atividade.id,
        laaidoperador: this.lancamento.operador.id,
        laaidpropriedade: this.lancamento.propriedade.id,
        laadatacriacao: new Date(),
        laaidnuvem: 'null',
        laaidusuario: idnuvem
      }  

      if (Object.values(novoApontamento).every((item) => item !== undefined)) {
        const validate = db.lancamento.add(novoApontamento);
        db.lancamentoatividade.add(novoApontamentoNuvem);
        
        WsEnviaDados({tipo: 'postLancamentoAtividade', msg: novoApontamentoNuvem });
        console.log("nuvem", novoApontamentoNuvem);

        ['propriedade','operador','atividade','data'].forEach((item) => {
          sessionStorage.removeItem(LANCAMENTO_STORAGE[item])
        });
        if (validate) {
          this.$router.replace({
            ...this.$route,
            name: ROUTE_APONTAMENTO.name,
          });
        }
      } else {
        errorHandler("Alguns campos não foram preenchidos.");
      }
    },
    
    async $_findDb() {
      this.autocomplete.atividade = await db.atividadeabastecimento.toArray();
      this.autocomplete.operador = await db.pessoa.toArray();
      this.autocomplete.propriedade = await db.propriedade.toArray();
      this.loading = false;
      return null;
    },
    
    async $_setupCampos() {
      this.lancamento.atividade = getSessionStorageParse(LANCAMENTO_STORAGE.atividade,'');
      this.lancamento.operador = getSessionStorageParse(LANCAMENTO_STORAGE.operador, '');
      this.lancamento.propriedade = getSessionStorageParse(LANCAMENTO_STORAGE.propriedade,'');
    },
    
    async $_toStorage(tabela) {
      sessionStorage.setItem(LANCAMENTO_STORAGE[tabela], JSON.stringify(this.lancamento[tabela]));
    }
  },
  created() {
    this.$_findDb();
    this.$_setupCampos();
    SetupWebSocket();
  },
};
</script>

<style scoped>
.center {
  text-align: center;
  width: 100%;
}
.linha {
  display: flex;
  justify-content: space-around;
}
.divinferior {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 5px;
  right: 0%;
}

.botaoinferior {
  color: white;
}
</style>