<template>
    <layout-default-app-bar :backTo="routelancamento">
        Histórico da Atividade
    </layout-default-app-bar>
</template>

<script>
import LayoutDefaultAppBar from '@/layouts/default/AppBar.vue';
import { ROUTE_APONTAMENTO } from '@/constants/ROUTES';

export default {
    name: 'ViewAtividadeAppBar',
    components: {
        LayoutDefaultAppBar
    },
    data() {
        return {
            routelancamento: ROUTE_APONTAMENTO,
        }
    }
}
</script>