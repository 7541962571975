<template>
  <layout-default-appbar :back-to="rotaapontamento">
    Cadastrar Apontamento
  </layout-default-appbar>
</template>

<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue';

import { ROUTE_APONTAMENTO } from '@/constants/ROUTES';

export default {
  name: 'ViewFrotaAppBar',

  components: {
    LayoutDefaultAppbar,
  },

  data() {
    return {
      rotaapontamento: ROUTE_APONTAMENTO,
    };
  },
};
</script>
