<template>
  <app-card-apontamento-skeleton v-bind="$attrs" v-on="$listeners" :actions="actions"
  @click="$_emitter">
    <template #contrato >

    <div class="cardapontamento">
      <div v-if="value.id" class="idapontamento"> {{ value.id }} </div>
      <div v-if="value.landatacriacao">
        {{$_formataData(value.landatacriacao,'DD/MM/YYYY')}}
        {{$_formataData(value.landatacriacao,'HH:mm')}}
      </div>
      <div v-if="value.lanoperador">- {{value.lanoperador}} </div>
    </div>
    </template>
    <template #detalhe1>
      <div v-if="value.lanatividade"> {{value.lanatividade}}</div>
      <div  v-if="value.lanpropriedade"> {{value.lanpropriedade}}</div>
    </template>
  </app-card-apontamento-skeleton>
</template>

<script>
import moment from 'moment-timezone';
import AppCardApontamentoSkeleton from './Skeleton.vue';

export default {
  name: 'AppCardApontamento',

  components: {
    AppCardApontamentoSkeleton,
  },

  methods: {
    $_formataData(date, mascara) {
      return moment.tz(date, 'America/Sao_Paulo').local().format(mascara);
    },

    $_emitter() {
      this.$emit('innerClick', this.value.id);
    },
  },

  props: {
    value: Object,
    actions: Boolean,
  },
};
</script>

/<style scoped>
#principal{
  display: block;
}

.flex-container {
  display: flex;
}

.flex-container > * {
  margin-right: 3px;
}

.idapontamento {
  margin-right: 10px;
}

.cardapontamento {
  display: flex;
  flex-wrap: wrap;
}
.status{
    color: rgb(4, 156, 4);
    text-align: right;
    font-weight: bold;
}
</style>
