<template>
    <div v-if="!loading" class="wrapper">
        <div class="semapontamento" v-if="valores.length === 0">
            <br/>
            Não foi encontrado nenhum apontamento.
        </div>
        <div class="divnovo">
            <v-btn  height="45" color="green botaoinferior" @click="$_lancarAtividade">
                Novo
            </v-btn>
        </div>
        <div v-for="item in valores" :key="item.lanidlancamento" ref="lista">
            <!-- <app-card-apontamento :value="item" @innerClick="$_onClickCard" class="card">
            </app-card-apontamento> -->
            <app-card-apontamento :value="item" class="card" @innerClick="$_onClickCard">
            </app-card-apontamento>
        </div>
    </div>
</template>

<script>
import AppCardApontamento from '../../components/app/card/apontamento/Index.vue';
import { ROUTE_AP_CADASTRO, ROUTE_READ_ATIVIDADE } from '../../constants/ROUTES';
import { LANCAMENTO_STORAGE } from '@/constants/STORAGE';
import { LANCAMENTO_QUERIES } from '@/constants/QUERY_FILTERS';
import db from '@/plugins/dexie';
import deepEquals from '@/utils/deep-equals';
import { getQueryValue, setQueryValue } from '@/services/query-string';
//import { SetupWebSocket } from '@/utils/ws/websocket';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}


export default {
    name: "ViewApontamento",
    components:{
        AppCardApontamento,
    },
    data(){
        return{
            loading: false,
            filters: {
              createdFrom: getSessionStorage(LANCAMENTO_STORAGE.createdFrom, null),
              createdBefore: getSessionStorage(LANCAMENTO_STORAGE.createdBefore, null)
            }
        }
    },
    methods: {
    async $_load() {
      this.loading = true;
      console.log(new Date(this.filters.createdFrom), new Date(`${this.filters.createdBefore}T23:59:00`))
      try {
        this.valores = await db.lancamento.where('landatacriacao').between(
          new Date(this.filters.createdFrom), new Date(`${this.filters.createdBefore}T23:59:00`)
        ).toArray();
        console.log("Valores: ", this.valores)
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
      return null;
    },
    
    async $_onClickCard(idCard){
      sessionStorage.setItem(LANCAMENTO_STORAGE.idlancamento, idCard);
      this.$router.replace({
        ...this.$route,
        name: ROUTE_READ_ATIVIDADE.name,
      })
    },

    $_lancarAtividade() {
      sessionStorage.setItem(LANCAMENTO_STORAGE.idlancamento, '0');
      ['propriedade','operador','atividade','data'].forEach((item) => {
        sessionStorage.removeItem(LANCAMENTO_STORAGE[item]);
      });
      this.$router.replace({
        ...this.$route,
        name: ROUTE_AP_CADASTRO.name,
      });
    },
    },
    watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {

        const filters = {
          createdFrom: getQueryValue(LANCAMENTO_QUERIES.createdFrom, this.filters.createdFrom),
          createdBefore: getQueryValue(
            LANCAMENTO_QUERIES.createdBefore,
            this.filters.createdBefore,
          ),
        };

        if (!deepEquals(this.filters, filters)) {
          this.filters.createdFrom = filters.createdFrom;
          this.filters.createdBefore = filters.createdBefore;

          if (this.$refs.lista) {
            this.$_load();   
          }
        }
      },
    },

    options: {
      deep: true,
      handler() {
        setQueryValue(LANCAMENTO_QUERIES.createdFrom, this.filters.createdFrom);
        setQueryValue(LANCAMENTO_QUERIES.createdBefore, this.filters.createdBefore);
      },
    },
    },

    created(){
        this.$_load();
    }
}
</script>

<style scoped>
    .semapontamento {
      text-align: center;
    }
    .botaoinferior {
      color:white;
    }
    .divnovo {
      text-align: center;
      width: 100%;
      margin-top: 20px;
    }
    .wrapper{
        
    }
    .card {
    padding: 3px;
    margin: 15px 10px 0px 10px
    }

</style>