<template>
  <layout-default-appbar :backTo="rota">
    Capturar Imagem
  </layout-default-appbar>
</template>
<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue'
import { ROUTE_VISITA_INFO } from '@/constants/ROUTES'
export default {
  name: 'ViewCameraAppbar',
  components: {
    LayoutDefaultAppbar,
  },
  data(){
    return {
      rota: ROUTE_VISITA_INFO
    }
  }
}
</script>