<template>
    <layout-default-appbar filterable>
        Apontamento
    </layout-default-appbar>
</template>

<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue'

export default {
    name: "ViewApontamentoAppBar",
    components: {
        LayoutDefaultAppbar
    }
};
</script>