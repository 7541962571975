<template>
  <core-view class="flex-column" fill-height>
    <template class="wrapper">
      <div class="center" v-if="!loading">
        <v-form>
          <div class="linha">
            <v-text-field
              outlined
              dense
              class="readonlyfield"
              label="Cultura"
              v-model="visita.cultura"
              itemLabel="culdescricao"
              readonly
            >
            </v-text-field>
          </div>
          <div class="linha">
            <v-text-field
              dense
              outlined
              class="readonlyfield"
              label="Propriedade"
              v-model="visita.propriedade"
              itemLabel="pronome"
              readonly
            >
            </v-text-field>
          </div>
          <div class="linha">
            <v-text-field
              dense
              outlined
              class="readonlyfield"
              label="Produtor"
              v-model="visita.produtor"
              itemLabel="pesnome"
              readonly
            >
            </v-text-field>
          </div>
          <div>
            <v-btn
              small
              color="green"
              class="white--text botaocamera"
              @click="$_cliqueCamera"
            >
              Adicionar Imagem
              <v-icon> mdi-camera </v-icon>
            </v-btn>
          </div>

          <template :activator="cardobs">
            <div v-if="imagens.length === 0" class="my-5 center">
              Nenhuma imagem foi encontrada.
            </div>

            <div class="scroll center">
              <v-card
                class="card mx-auto"
                outlined
                v-for="(obs, index) in imagens"
                :key="index"
                ref="cardobs"
                @click="$_clique(index)"
              >
                <v-card-title class="title">
                  {{ obs.imgnome }}
                </v-card-title>
                <v-card-text class="descricao">
                  {{ obs.imgdescricao }}
                </v-card-text>
                <v-img class="img mx-auto" contain :src="obs.imgdata"></v-img>
              </v-card>
            </div>
          </template>

          <v-dialog v-model="dialog">
                  <v-card-title
                    class="header headline green white--text"
                    id="card-superior"
                  >
                    <div class="header" id="label">Editar Observação</div>
                    <v-icon  id="icone"> mdi-pencil </v-icon>
                  </v-card-title>
            <div class="center scroll">
              <v-card class="content">
                <div>
                  <v-text-field
                    outlined
                    dense
                    class="dialoginput"
                    label="Titulo da Imagem"
                    v-model="dialogTitle"
                  >
                  </v-text-field>
                  <v-textarea
                    class="dialoginput"
                    dense
                    no-resize
                    rows="2"
                    maxlength="200"
                    outlined
                    label="Descrição"
                    v-model="dialogDesc"
                  >
                  </v-textarea>
                </div>

                <v-img
                  class="imgdialog mx-auto"
                  contain
                  :src="dialogSrc"
                ></v-img>

                <div class="divinferior">
                  <v-btn
                    height="30"
                    small
                    class="red botaoinferior"
                    @click="$_confirmarExclusao()"
                  >
                    Excluir
                  </v-btn>
                  <v-btn
                    height="30"
                    small
                    class="green botaoinferior"
                    @click="$_salvaDados()"
                  >
                    Salvar
                  </v-btn>
                </div>
              </v-card>
            </div>
          </v-dialog>

          <v-dialog v-model="dialogExclusao">
            <v-card-title
              class="header headline red white--text"
              id="card-superior"
            >
              <div class="header" id="label">Excluir Observação</div>
              <v-icon  id="icone"> mdi-alert </v-icon>
            </v-card-title>
            <div class="center">
              <v-card class="mx-auto">
                  <div class="confirmacao">
                    <v-card-text class="confirmacao">
                      Deseja realmente excluir esta observação?
                    </v-card-text>
                  </div>
                
                <div class="divinferior">
                  <v-btn
                    height="30"
                    small
                    id="voltar"
                    class="botaoinferior my-5"
                    @click="dialogExclusao = false;"
                  >
                    Voltar
                  </v-btn>
                  <v-btn
                    height="30"
                    small
                    class="red botaoinferior my-5"
                    @click="$_excluirDados()"
                  >
                    Excluir
                  </v-btn>
                </div>
              </v-card>
            </div> 
          </v-dialog>
          
        </v-form>
      </div>
    </template>
  </core-view>
</template>
<script>
import CoreView from "@/components/core/view/Index.vue";
import { VISITA_STORAGE, VISITA_INFO_STORAGE } from "@/constants/STORAGE";
import moment from "moment-timezone";
import db from "@/plugins/dexie";
import { ROUTE_CAMERA } from "@/constants/ROUTES";

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: "ViewVisitaInfo",
  components: {
    CoreView,
  },
  data() {
    return {
      imagens: [],
      dialog: false,
      dialogExclusao: false,
      dialogTitle: null,
      dialogSrc: null,
      dialogDesc: null,
      dialogIndex: null,
      loading: true,
      cardobs: null,
      visita: {
        cultura: null,
        produtor: "",
        propriedade: "",
        data: moment().format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    $_clique(idimg) {
      console.log("Clicou", idimg);
      this.dialogTitle = this.imagens[idimg].imgnome;
      this.dialogSrc = this.imagens[idimg].imgdata;
      this.dialogDesc = this.imagens[idimg].imgdescricao;
      this.dialogIndex = idimg;
      sessionStorage.setItem(
        VISITA_INFO_STORAGE.titulo,
        this.imagens[idimg].imgnome
      );
      sessionStorage.setItem(
        VISITA_INFO_STORAGE.observacao,
        this.imagens[idimg].imgdescricao
      );
      sessionStorage.setItem(
        VISITA_INFO_STORAGE.idinfo,
        this.imagens[idimg].id
      );
      this.dialog = true;
    },
    async $_SetupCamposReadOnly(idvisita) {
      const tabela = db.visita.where({ id: idvisita }).first();
      const arr = await db.imagem.toArray();
      console.log(arr);
      tabela.then((item) => {
        this.visita.cultura = item.viscultura;
        this.visita.propriedade = item.vispropriedade;
        this.visita.produtor = item.visprodutor;
        arr.map((x) => {
          if (x.idvisita === `${idvisita}`) {
            return this.imagens.push(x);
          }
        });
        console.log("img1", this.imagens);
        this.loading = false;
      });
    },
    $_cliqueCamera() {
      ['observacao','titulo','idinfo','imagem'].forEach((item) => {
        sessionStorage.removeItem(VISITA_INFO_STORAGE[item]);
      });
      this.$router.push({
        ...this.$route,
        name: ROUTE_CAMERA.name,
      });
    },
    async $_salvaDados() {
      const idimg = parseInt(getSessionStorage(VISITA_INFO_STORAGE.idinfo))
      await db.imagem.where("id").equals(idimg).modify({
        imgdescricao: this.dialogDesc,
        imgnome: this.dialogTitle
      });
      this.imagens.findIndex((p) => {
        if (p.id === idimg){
          p.imgdescricao = this.dialogDesc,
          p.imgnome = this.dialogTitle
        }
      });
      console.log(this.imagens)
      this.dialog = false;
    },

    $_confirmarExclusao() {
      this.dialogExclusao = true;
    },
    $_excluirDados() {
      const idexclusao = parseInt(getSessionStorage(VISITA_INFO_STORAGE.idinfo));
      const arrImagens = this.imagens;
      arrImagens.splice(arrImagens.findIndex((p) => p.id === idexclusao), 1);
      db.imagem.where("id").equals(idexclusao).delete();
      
      this.dialogExclusao = false;
      this.dialog = false;
    },
  },

  beforeCreate() {
    const idvisita = getSessionStorage(VISITA_STORAGE.idvisita);
    this.idvisita = idvisita;
  },
  created() {
    this.$_SetupCamposReadOnly(Number(this.idvisita));
  },
};
</script>

<style scoped>
.v-sheet--offset {
  top: 5px;
  position: relative;
}

#icone{
  color: white;
  float: right;
}

.readonlyfield{
  margin-bottom: 7%;
  font-size: 0.9em;
}
.center {
  width: 100%;
  text-align: center;
}

.linha {
  display: flex;
  margin-bottom: -30px;
}

.divinferior {
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  bottom: 5px;
  right: 0%;
}

.botaoinferior {
  color: white;
  margin-bottom: 8px;
}
#voltar {
  color: black;
  margin-bottom: 8px;
}

@media screen and (min-width: 600px) {
  .tablet {
    display: flex;
  }
}
.botaocamera {
  margin: 10px;
}
.img {
  max-height: 120px;
  max-width: 120px;
}
.imgdialog {
  margin-top: 5px;
  margin-bottom: 40px;
  max-height: 200px;
  max-width: 200px;
}
.scroll {
  max-height: 400px;
  overflow: scroll;
}
.title {
  justify-content: center;
  margin-bottom: -15px;
}

.wrapper {
  overflow-y: scroll;
}
.content {
  padding: 15px;
  max-height: 400px;
}
.card {
  margin-bottom: 5%;
  padding-bottom: 5%;
  width: 80%;
}
.descricao {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dialoginput {
  margin-bottom: -10px;
  font-size: 0.8rem;
}
.label {
  margin-top: 5%;
}
.header {
  font-size: 1.1rem;
  padding: 1%;
  padding-left: 5px;
  justify-content: space-between;
}
.confirmacao{
  margin-bottom: 30px;
  font-weight: bold;
  display: inline-block;
}
</style>