import Dexie from 'dexie';

const db = new Dexie('AgroCampo');

db.version(1).stores({
  usuario: '++id, useidnuvem, usenome, usecpf, usesenha, usedataatualizacao',
  veiculo: '++id, veiidveiculo, veiidnuvem, veiplaca, veitipoveiculo, veimarca, veicapacidade, veifrota, veidataatualizacao, veitipomaquinario',
  propriedade: '++id, proidnuvem, proidpropriedade, pronome, prodataatualizacao',
  operacao: '++id, opeidnuvem, opeidoperacao, opedescricao, opedataatualizacao',
  produto: '++id, proidnuvem, proidproduto, prodescricao, procodigo, provalor, prodataatualizacao',
  estoque: '++id, moeidnuvem, moeidmovimentacaoestoque, moedescricao,  moecategoria, moeidproduto, moedataatualizacao',
  localmovimentacaoestoque: '++id, lmeidnuvem, lmeidlocalmovimentacaoestoque,lmedescricao, lmeidproduto, lmedataatualizacao',
  pessoa: '++id, pesidnuvem, pesidpessoa, pesnome, pesnomefantasia, pescpf, pesdataatualizacao',
  atividadeabastecimento: '++id, ataidnuvem, ataidatividadeabastecimento, atadescricao, atadataatualizacao',
  abastecimento: `++id, abaidnuvem, abadata, abaoperacao, abafrota,
  abaoperadorveiculo, abaodometro, abatipoodometro, abaquantidade,
  abaorigem, abaoperador, abalocal, abaatividade, abadescricao,
  abafinalizado, abaproduto`,
  logexclusao: '++id, loedataexclusao',
  lancamentoatividade: '++id, laaidpropriedade, laaidatividade, laaidoperador, laadatacriacao, laaidnuvem',
  cultura: '++id, culidnuvem, culidcultura, culdescricao, culidpropriedade, culdataatualizacao',
  visitatecnica: '++id, vitidnuvem, vitidcultura, vitidprodutor, vitidpropriedade, vitdatacriacao',
  visita: '++id, visidnuvem, visidcultura, visidprodutor, visidpropriedade, visdatacriacao',
  imagem: '++id, idvisita, imgdata, imgnome, imgdescricao'
});

db.version(2).stores({
  usuario: '++id, useidnuvem, usenome, usecpf, usesenha, usedataatualizacao',
  veiculo: '++id, veiidveiculo, veiidnuvem, veiplaca, veitipoveiculo, veimarca, veicapacidade, veifrota, veidataatualizacao, veitipomaquinario',
  propriedade: '++id, proidnuvem, proidpropriedade, pronome, prodataatualizacao',
  operacao: '++id, opeidnuvem, opeidoperacao, opedescricao, opedataatualizacao',
  produto: '++id, proidnuvem, proidproduto, prodescricao, procodigo, provalor, prodataatualizacao',
  estoque: '++id, moeidnuvem, moeidmovimentacaoestoque, moedescricao,  moecategoria, moeidproduto, moedataatualizacao',
  localmovimentacaoestoque: '++id, lmeidnuvem, lmeidlocalmovimentacaoestoque,lmedescricao, lmeidproduto, lmedataatualizacao',
  pessoa: '++id, pesidnuvem, pesidpessoa, pesnome, pesnomefantasia, pescpf, pesdataatualizacao',
  atividadeabastecimento: '++id, ataidnuvem, ataidatividadeabastecimento, atadescricao, atadataatualizacao',
  abastecimento: `++id, abaidnuvem, abadata, abaoperacao, abafrota,
  abaoperadorveiculo, abaodometro, abatipoodometro, abaquantidade,
  abaorigem, abaoperador, abalocal, abaatividade, abadescricao,
  abafinalizado, abaproduto`,
  logexclusao: '++id, loedataexclusao',
  movimentacaoabastecimento: `++id, moatipooperacao, moaidveiculo, moaidpessoa, moatipoodometro,
  moaodometro, moaquantidade, moaidprodutoorigem, moaidpessoaorigem, moaidpropriedade, moaidatividade,
  moaobservacao, moaidlocalarmazenamentoorigem, moadatacriacao, moaidusuario, moaidnuvem`,
  lancamento: '++id, lanidpropriedade, lanidatividade, lanidoperador, landatacriacao, lanidnuvem',
  lancamentoatividade: '++id, laaidpropriedade, laaidatividade, laaidoperador, laadatacriacao, laaidnuvem',
  cultura: '++id, culidnuvem, culidcultura, culdescricao, culidpropriedade,  culdataatualizacao',
  visitatecnica: '++id, vitidnuvem, vitidcultura, vitidprodutor, vitidpropriedade, vitdatacriacao',
  visita: '++id, visidnuvem, visidcultura, visidprodutor, visidpropriedade, visdatacriacao',
  imagem: '++id, idvisita, imgdata, imgnome, imgdescricao'
});

export default db;
