<template>
  <layout-default-app-bar :backTo="rotaanalise">
    {{'Visita Técnica'}}
  </layout-default-app-bar>
</template>

<script>
import LayoutDefaultAppBar from '@/layouts/default/AppBar.vue'
import { ROUTE_VISITA } from '@/constants/ROUTES'
import { VISITA_INFO_STORAGE } from '@/constants/STORAGE'
export default {
  name: 'ViewAnaliseAppBar',
  components: {
    LayoutDefaultAppBar,
  },
  data(){
    return {
      rotaanalise: ROUTE_VISITA,
    }
  },
  created(){
    sessionStorage.removeItem(VISITA_INFO_STORAGE.titulo);
  }
}
</script>