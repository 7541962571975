<template>
  <core-view class="flex-column" fill-height>
    <template>
      <div class="center" v-if="!loading">
        <form>
          <div class="linha">
            <new-text-field label="Atividade"
            v-model="lancamento.atividade" itemLabel="atadescricao"  readonly>
            </new-text-field>
          </div>
          <div class="linha">
            <new-text-field label="Propriedade"
            v-model="lancamento.propriedade" itemLabel="pronome"  readonly>
            </new-text-field>
          </div>
          <div class="linha">
            <new-text-field label="Operador"
            v-model="lancamento.operador" itemLabel="pesnome"  readonly>
            </new-text-field>
          </div>
        </form>
          </div>
    </template>
  </core-view>
</template>
<script>
import CoreView from '@/components/core/view/Index.vue'
import NewTextField from '@/components/core/new-text-field/Index.vue'
import { LANCAMENTO_STORAGE } from '@/constants/STORAGE';
import moment from 'moment-timezone'
import db from '@/plugins/dexie'

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
    name: 'ViewReadAtividade',
    components: {
        CoreView, NewTextField,
    },
    data() {
      return {
        loading: true,

        lancamento: {
          atividade: '',
          operador: '',
          propriedade: '',
          data: moment().format('YYYY-MM-DD'),
      }
      }
    },
    methods:{
      $_SetupCamposReadOnly(idlancamento){
        const tabela = db.lancamento.where({id: idlancamento}).first();
        console.log('Tabela', tabela)
        tabela.then((item) => {
          this.lancamento.atividade = item.lanatividade;
          this.lancamento.propriedade = item.lanpropriedade;
          this.lancamento.operador = item.lanoperador;
          this.loading = false;
        })
      }
    },

    beforeCreate() {
    const idlancamento = getSessionStorage(LANCAMENTO_STORAGE.idlancamento);
    this.idlancamento = idlancamento;
  },
    created(){
      this.$_SetupCamposReadOnly(Number(this.idlancamento))
    }
}
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .linha {
    display: flex;
    margin-bottom:-30px;
  }

  .linha-justificada {
    display: flex;
    justify-content: space-around;
  }

  .divinferior {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 5px;
    right: 0%;
  }

  .botaoinferior {
    color: white;
  }

  @media screen and (min-width:600px) {
    .tablet {
      display: flex;
    }
  }

</style>